import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { TitleText, Text } from '../../lib/styles/styledAbout';
import palette from '../../lib/styles/palette';

// 미모티콘 수정 필요
import reviewer1 from '../../assets/about/reviewer1.png';
import reviewer2 from '../../assets/about/reviewer2.png';
import reviewer3 from '../../assets/about/reviewer3.png';
import reviewer4 from '../../assets/about/reviewer4.png';

import up from '../../assets/recruitment/up.png';
import down from '../../assets/recruitment/down.png';

const Review = () => {
  const isTablet = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [detail, setDetail] = useState(false); // 드롭다운 세모 이미지

  const _handleClick = () => {
    setDetail(!detail);
  };

  return (
    <>
      {/* 모바일 - 제목 모달 */}
      {isMobile && (
        <DropdownContainer onClick={_handleClick}>
          <DropdownText green>우수 활동 퍼비</DropdownText>
          <DropdownText>의 활동 후기</DropdownText>
          {detail ? (
            <DropdownImg src={up} alt="up" />
          ) : (
            <DropdownImg src={down} alt="down" />
          )}
        </DropdownContainer>
      )}

      {(isMobile && detail) || (!isMobile && !detail) ? (
        <>
          {isMobile ? (
            <></>
          ) : (
            <TitleContainer>
              <TitleText green>우수 활동 퍼비</TitleText>
              <TitleText>의 활동 후기</TitleText>
            </TitleContainer>
          )}

          <ReviewContainer>
            <Reviewer src={reviewer1} />
            <ContentBox>
              <Text>
                이펍의 합동 토이 프로젝트와 SWS프로젝트로 백엔드 개발자와
                디자이너와 함께하는 협업을 경험할 수 있었고, 위 경험들을
                바탕으로 처음으로 공모전에 출품을 해보았고 수상까지 할 수
                있었습니다. 프로젝트 뿐만 아니라 매주 진행되는 세미나와 스터디에
                참여하면서 1년 동안 많이 성장 할 수 있었습니다. 이펍 최고💕 (3기
                송지민, FrontEnd 리드 개발자)
              </Text>
            </ContentBox>
          </ReviewContainer>

          <ReviewContainer>
            {isTablet ? (
              <>
                <Reviewer src={reviewer2} />
                <ContentBox>
                  <Text>
                    이펍은 비전공자여서 막연하고 힘들었던 저의 인생에 큰 영향을
                    준 한 줄기의 빛같은 동아리입니다. 코딩을 배운지 2개월만에
                    개발자가 되고 싶다는 막연한 꿈을 꾸고 이펍에 지원했었던게
                    엊그제 같아요. 이펍에 있으면서, 임원진들이 세미나와
                    프로젝트의 퀄리티를 위해서 정말 많이 희생하고 노력한 것을
                    저는 부원으로서 많이 느꼈습니다. 덕분에 저는 편하게 이펍에서
                    개발 실력과 좋은 인연이라는 두마리 토끼를 다 얻을 수
                    있었습니다! 그리고 감사하게도 ‘1학기 우수 개발자’로 뽑히는
                    영광까지 얻어서, 개발자로서의 자신감도 많이
                    얻었습니다.이펍에서 얻은게 많아서 모든 걸 나열하긴 힘들지만,
                    확실한 것은 저희 학교에 이렇게 좋은 동아리가 있다는 것,
                    그리고 제가 1년동안 그 동아리에서 의미있고 행복한 나날들을
                    보냈다는 것은 알려드리고 싶어요. 이펍이 지금처럼 개발자로의
                    길을 막 시작하려는 학생들에게 좋은 이정표가 되는 동아리가
                    되길 바랍니다. 이펍 화이팅! (3기 오혜린, FrontEnd 인턴
                    개발자)
                  </Text>
                </ContentBox>
              </>
            ) : (
              <>
                <ContentBox>
                  <Text>
                    이펍은 비전공자여서 막연하고 힘들었던 저의 인생에 큰 영향을
                    준 한 줄기의 빛같은 동아리입니다. 코딩을 배운지 2개월만에
                    개발자가 되고 싶다는 막연한 꿈을 꾸고 이펍에 지원했었던게
                    엊그제 같아요. 이펍에 있으면서, 임원진들이 세미나와
                    프로젝트의 퀄리티를 위해서 정말 많이 희생하고 노력한 것을
                    저는 부원으로서 많이 느꼈습니다. 덕분에 저는 편하게 이펍에서
                    개발 실력과 좋은 인연이라는 두마리 토끼를 다 얻을 수
                    있었습니다! 그리고 감사하게도 ‘1학기 우수 개발자’로 뽑히는
                    영광까지 얻어서, 개발자로서의 자신감도 많이
                    얻었습니다.이펍에서 얻은게 많아서 모든 걸 나열하긴 힘들지만,
                    확실한 것은 저희 학교에 이렇게 좋은 동아리가 있다는 것,
                    그리고 제가 1년동안 그 동아리에서 의미있고 행복한 나날들을
                    보냈다는 것은 알려드리고 싶어요. 이펍이 지금처럼 개발자로의
                    길을 막 시작하려는 학생들에게 좋은 이정표가 되는 동아리가
                    되길 바랍니다. 이펍 화이팅! (3기 오혜린, FrontEnd 인턴
                    개발자)
                  </Text>
                </ContentBox>
                <Reviewer src={reviewer2} />
              </>
            )}
          </ReviewContainer>

          <ReviewContainer>
            <Reviewer src={reviewer3} />
            <ContentBox>
              <Text>
                이펍의 다양한 프로젝트에 참여하면서 어려운 순간도 있었지만,
                팀으로서 여러 공모전에서 의미 있는 성과를 얻어 감사한 한
                해였습니다. 학교생활을 하면서 동아리 활동과 다양한 대외 활동을
                병행하는데 개발까지 잘하시는 퍼비분들을 보면서 감탄하기도 했고,
                저도 많은 자극을 얻었습니다. 퍼비분들로부터 배울 점도 많았지만,
                이펍 활동 자체를 통해 배울 수 있는 것도 많았습니다. 1년이라는
                활동 기간을 보장받기 때문에 이펍에서는 이론과 실습을 고루 경험할
                수 있었습니다. 특히 결과물을 위한 프로젝트에만 매달리지 않고,
                세미나와 스터디를 통해 이론 학습을 병행한다는 점이
                만족스러웠습니다. 세미나는 3기에 걸쳐 임원진들이 다듬고 또
                다듬어 만드신 자료를 활용하고, 모든 동아리원들이 세미나 발표에
                참여하게 되는데요. 그래서인지 양질의 세미나를 접할 수
                있었습니다.😉 스터디의 경우 개발 서적 스터디부터 자격증 스터디에
                이르기까지 다양한 스터디가 권장되는 환경이었습니다. 혼자
                공부하면 해이해지기 쉬운데, 스터디원들과 함께 개발 서적도 읽고
                자격증도 취득하니 과정은 힘들어도 결과는 만족스러웠네요. 다음
                기수는 얼마나 더 좋아질지 기대가 되면서 , 한편으로는 다음
                기수분들이 부럽기도 하네요.🥳 웹개발에 관심이 있으시다면
                지원하시는 것을 추천드립니다! (3기 박가영, BackEnd 리드 개발자)
              </Text>
            </ContentBox>
          </ReviewContainer>

          <ReviewContainer>
            {isTablet ? (
              <>
                <Reviewer src={reviewer4} />
                <ContentBox>
                  <Text>
                    기본기부터 탄탄하게 쌓아올려가는 체계적인 세미나부터
                    토이프로젝트, SWS프로젝트, 2학기 프로젝트까지 우수한 이펍
                    부원들과 함께 여러 프로젝트를 진행하며 초반에는 정말 어렵고
                    힘든 시간이었지만 그만큼 많이 배우고 성장하는 한 해가
                    되었습니다. 인생 처음으로 이펍에서 진행한 프로젝트를 통해
                    공모전 수상까지 이루어내는 큰 성취를 경험하기도
                    하였구요\(^0^)/ 이렇게 성장할 수 있도록 도움을 준 이펍
                    운영진분들과 부원들에게 진심으로 감사의 인사를 전하고
                    싶어요! 24년도 운영진분들에게도 힘찬 활동을 기대하며
                    응원하고 있을게요🍀💚 여러분들도 이펍에서 많은 경험을 쌓고
                    많이 성장하는 소중한 시간이 되길 바라겠습니다! 이펍 최고❤️
                    (3기 송채원, BackEnd 인턴 개발자)
                  </Text>
                </ContentBox>
              </>
            ) : (
              <>
                <ContentBox>
                  <Text>
                    기본기부터 탄탄하게 쌓아올려가는 체계적인 세미나부터
                    토이프로젝트, SWS프로젝트, 2학기 프로젝트까지 우수한 이펍
                    부원들과 함께 여러 프로젝트를 진행하며 초반에는 정말 어렵고
                    힘든 시간이었지만 그만큼 많이 배우고 성장하는 한 해가
                    되었습니다. 인생 처음으로 이펍에서 진행한 프로젝트를 통해
                    공모전 수상까지 이루어내는 큰 성취를 경험하기도
                    하였구요\(^0^)/ 이렇게 성장할 수 있도록 도움을 준 이펍
                    운영진분들과 부원들에게 진심으로 감사의 인사를 전하고
                    싶어요! 24년도 운영진분들에게도 힘찬 활동을 기대하며
                    응원하고 있을게요🍀💚 여러분들도 이펍에서 많은 경험을 쌓고
                    많이 성장하는 소중한 시간이 되길 바라겠습니다! 이펍 최고❤️
                    (3기 송채원, BackEnd 인턴 개발자)
                  </Text>
                </ContentBox>
                <Reviewer src={reviewer4} />
              </>
            )}
          </ReviewContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Review;

const DropdownContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(225, 225, 225, 0.15);
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 30px;
`;

const DropdownText = styled.div`
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.green ? palette.green : 'white')};
`;

const DropdownImg = styled.img`
  position: absolute;
  right: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 100px 0px;
`;

const Reviewer = styled.img`
  width: 300px;
  @media (max-width: 1023px) {
    width: 240px;
  }
  @media (max-width: 767px) {
    width: 180px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

const ContentBox = styled.div`
  width: 700px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 15px;
  }
`;
