import photo1 from './project7-1.png';
import photo2 from './project7-2.png';
import photo3 from './project7-3.png';

const project7 = {
  id: 7,
  name: '라꾸라꾸',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '김서연 신유진 안민경 박희진',
    },
    {
      position: 'BackEnd Developer',
      names: '강민지 최예원 황서정 권지윤',
    },
    {
      position: 'UXUI Designer',
      names: '구도연 최소이',
    },
  ],
  about:
    '라꾸라꾸는 웹 기반 공유 다이어리 서비스로, 쉽고 간단하게 다이어리를 작성하여 나만의 라이프스타일을 꾸미고 이를 공유하고자 하는 유저들을 위해 제작되었습니다. 웹 기반 서비스이기 때문에 간편하게 휴대하고 걱정없이 저장할 수 있으며, 나의 일기를 한 눈에 확인하고 친구와 공유하며 댓글로 소통할 수 있습니다. 또한 기본 제공되는 300여종의 스티커 및 템플릿를 활용하여 간단하게 다이어리를 꾸밀 수 있으며 원하는 사진을 스티커로 만들어 나만의 다이어리를 만들 수 있습니다.',
  links: {
    website: 'https://lakku-lakku.netlify.app/',
    github: 'https://github.com/EFUB-LakkuLakku',
  },
  photos: [photo1, photo2, photo3],
};

export default project7;
