import photo1 from './project14-1.png';
import photo2 from './project14-2.png';
import photo3 from './project14-3.png';
import photo4 from './project14-4.png';

const project14 = {
  id: 14,
  name: 'SweetMe',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '최예빈, 권태영',
    },
    {
      position: 'BackEnd Developer',
      names: '조민서, 이한나',
    },
    {
      position: 'UXUI Designer',
      names: '최예빈, 권태영, 조민서, 이한나',
    },
  ],
  about:
    '스윗미는 동일한 목표를 지닌 사람들이 교류하며 함께 성장할 수 있도록 연결고리를 만들어주는 스터디 모집 서비스입니다. 스윗미에서는 카카오 로그인, 모집 글 작성, 카카오 간편결제를 이용한 홍보 기능 등을 제공합니다. 달콤함이 주는 긍정적인 에너지처럼 스터디 활동이 만들어나갈 시너지를 기대합니다.',
  links: {
    website: '',
    github: 'https://github.com/SweetMe-Sweetie',
  },
  photos: [photo1, photo2, photo3, photo4],
};

export default project14;
