import React, { useState } from 'react';
import styled from 'styled-components';
import { TitleText } from '../../lib/styles/styledAbout';
import palette from '../../lib/styles/palette';
import vectorCocktail from '../../assets/about/vectorCocktail.png';
import organizerButton from '../../assets/about/organizerButton.png';
import OrganizerModal from './OrganizerModal';
import { useNavigate } from 'react-router-dom';
const Organizer = () => {
  const _handleGotoGithub = (link) => {
    window.open(link, '_blank');
  };

  const [modal, setModal] = useState(false);
  const [position, setPosition] = useState(0);

  const _handleClick = () => {
    setModal(!modal);
    setPosition(window.pageYOffset);
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };
  return (
    <>
      <TitleContainer>
        <TitleText eng>EFUB ORGANIZER</TitleText>
      </TitleContainer>

      <Container>
        {/* 이번 기수 임원진 박스 컴포넌트 */}
        <OrganizerContainer>
          {organizerListGreen.map((item) => (
            <OrganizerBox
              key={item.id}
              onClick={() => _handleGotoGithub(item.github)}
            >
              <Role green>{item.role}</Role>
              <Name>{item.name}</Name>
            </OrganizerBox>
          ))}
          {organizerListBlue.map((item) => (
            <OrganizerBox
              key={item.id}
              onClick={() => _handleGotoGithub(item.github)}
            >
              <Role>{item.role}</Role>
              <Name>{item.name}</Name>
            </OrganizerBox>
          ))}
        </OrganizerContainer>

        {/* 전체 임원 보기 버튼 */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Width />
          <ButtonContainer>
            <Vector src={vectorCocktail} />
            <Button src={organizerButton} onClick={() => _handleClick()} />
          </ButtonContainer>
          <Width />
        </div>
      </Container>

      {/* 1~3기 임원진 리스트 모달 */}
      {modal && (
        <OrganizerModal position={position} _handleModal={_handleClick} />
      )}
    </>
  );
};

export default Organizer;

const Width = styled.div`
  width: 0;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 100px 0px;
  @media (max-width: 1023px) {
    margin: 100px 0px 10px 0px;
  }
  @media (max-width: 767px) {
    margin: 50px 0px 0px 0px;
  }
`;

const OrganizerBox = styled.div`
  width: 140px;
  height: 140px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-10px);
    transition-duration: 0.5s;
  }
`;

const OrganizerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`;

const Role = styled.div`
  color: ${(props) => (props.green ? palette.green : palette.blue)};
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const Name = styled.div`
  color: 'white';
  font-size: 22px;
  font-family: 'Roboto';
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 1767px) {
    margin: 50px 0px 0px 0px;
  }
`;

const ButtonContainer = styled.div`
  min-width: 450px;
  height: 350px;
  position: relative;
  @media (max-width: 1023px) {
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    min-width: 340px;
    height: 260px;
  }
`;

const Vector = styled.img`
  height: 320px;
  position: absolute;
  right: 0px;
  @media (max-width: 767px) {
    height: 240px;
  }
`;

const Button = styled.img`
  height: 170px;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  @media (max-width: 767px) {
    height: 140px;
  }
`;

const organizerListGreen = [
  {
    id: 1,
    role: '회장',
    name: '송지민',
    github: 'https://github.com/songing01',
  },
  {
    id: 2,
    role: '부회장',
    name: '이한나',
    github: 'https://github.com/hannah0226',
  },
  {
    id: 3,
    role: '총무',
    name: '차소연',
    github: 'https://github.com/Soyeon-Cha',
  },
];

const organizerListBlue = [
  {
    id: 1,
    role: '디자이너장',
    name: '김수연',
    github: 'https://www.instagram.com/s_yeon.723',
  },
  {
    id: 2,
    role: '프론트장',
    name: '이서진',
    github: 'https://github.com/529539',
  },
  {
    id: 3,
    role: '프론트장',
    name: '김민정',
    github: 'https://github.com/wowalswjd',
  },
  {
    id: 4,
    role: '백엔드장',
    name: '김예지',
    github: 'https://github.com/xyzwv',
  },
  {
    id: 5,
    role: '백엔드장',
    name: '모수지',
    github: 'https://github.com/nammsamm',
  },
];
