import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';
import up from '../../assets/recruitment/up.png';
import Button from '../../components/common/Button';
import StudentInfo from '../../components/recruitment/StudentInfo';
import DeveloperForm from '../../components/recruitment/DeveloperForm';
import DesignerForm from '../../components/recruitment/DesignerForm';
import {
  designerStackList,
  developerStackList,
  time,
} from '../../assets/recruitment/recuitmentForm';

import { getApplicationDetail, checkResult } from '../../lib/api/admin';
import { adminIdState } from '../../lib/store/adminAtom';
import { useRecoilValue } from 'recoil';

const AdminDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const idList = useRecoilValue(adminIdState);
  const [index, setIndex] = useState(idList.indexOf(Number(id)));
  const [data, setData] = useState();
  const getDetail = () =>
    getApplicationDetail(id)
      .then((res) => {
        setData(res);
        setIndex(idList.indexOf(Number(id)));
        if (!res.user.fixMid) setResult('uncertain');
        else {
          if (res.user.passMid) setResult('pass');
          else setResult('fail');
        }
      })
      .catch((err) => console.log(err));

  useEffect(() => {
    window.scrollTo(0, 0);
    getDetail();
  }, [id]);

  const [result, setResult] = useState('uncertain');
  const clickResult = (clickedResult) =>
    checkResult(id, clickedResult).then((res) => getDetail());

  const _handleNavigate = (type) =>
    type === 'back'
      ? navigate(`/${process.env.REACT_APP_ADMIN_PATH}/list/all`)
      : type === 'next'
      ? navigate(
          `/${process.env.REACT_APP_ADMIN_PATH}/list/all/${idList[index + 1]}`,
        )
      : type === 'prev'
      ? navigate(
          `/${process.env.REACT_APP_ADMIN_PATH}/list/all/${idList[index - 1]}`,
        )
      : undefined;

  return (
    <>
      {data && (
        <Wrapper>
          <h1>지원서 상세 조회</h1>
          <Button round blue width="15" filled={false}>
            {data.user.position === 'DESIGNER'
              ? '디자이너'
              : data.apply.applicationField}
          </Button>
          <Container>
            <StudentInfo data={data.user} readOnly={true} />
            {data.user.position === 'DESIGNER' ? (
              <DesignerForm
                onChange={undefined}
                onClickScore={undefined}
                onToggleStack={undefined}
                onToggleCheck={undefined}
                onToggleTime={undefined}
                apply={data.apply}
                stackList={designerStackList.map((item) =>
                  data.apply.tools.includes(item.label)
                    ? { ...item, checked: true }
                    : { ...item, checked: false },
                )}
                timeList={time.map((item) =>
                  data.apply.interviews.includes(item.label)
                    ? { ...item, checked: true }
                    : { ...item, checked: false },
                )}
                readOnly={true}
              />
            ) : (
              <DeveloperForm
                onChange={undefined}
                onClickPart={undefined}
                onClickScore={undefined}
                onToggleStack={undefined}
                onToggleCheck={undefined}
                onToggleTime={undefined}
                apply={{
                  ...data.apply,
                  applicationField:
                    data.apply.applicationField === '인턴개발자 - 프론트엔드'
                      ? 1
                      : data.apply.applicationField ===
                        '리드개발자 - 프론트엔드'
                      ? 2
                      : data.apply.applicationField === '인턴개발자 - 백엔드'
                      ? 3
                      : 4,
                }}
                stackList={developerStackList.map((item) =>
                  data.apply.tools.includes(item.label)
                    ? { ...item, checked: true }
                    : { ...item, checked: false },
                )}
                timeList={time.map((item) =>
                  data.apply.interviews.includes(item.label)
                    ? { ...item, checked: true }
                    : { ...item, checked: false },
                )}
                readOnly={true}
              />
            )}
          </Container>
          <Controller>
            <div>
              {[
                { result: 'pass', text: '✅ 합격' },
                { result: 'fail', text: '❌ 불합격' },
                { result: 'uncertain', text: '💭 미정' },
              ].map((item) => (
                <ResultButton
                  key={item.text}
                  isClicked={result === item.result}
                  onClick={() => clickResult(item.result)}
                >
                  {item.text}
                </ResultButton>
              ))}
            </div>
            <div>
              <img
                src={up}
                onClick={() =>
                  index === 0 ? undefined : _handleNavigate('prev')
                }
                className={index === 0 ? 'disabled' : undefined}
              />
              <span>{id}</span>
              <img
                src={up}
                onClick={() =>
                  index === idList.length - 1
                    ? undefined
                    : _handleNavigate('next')
                }
                className={index === idList.length - 1 ? 'disabled' : undefined}
              />
            </div>
            <Back onClick={() => _handleNavigate('back')}>
              리스트로 돌아가기
            </Back>
          </Controller>
        </Wrapper>
      )}
    </>
  );
};

export default AdminDetail;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Pretendard;
  padding: 5rem;

  button {
    cursor: default;
    margin: 2rem 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div,
  input {
    font-family: Pretendard !important;
  }
`;

const Controller = styled.div`
  padding: 5rem 0;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 3rem 0;
  }
  & > div:nth-child(2) {
    font-size: 1.5rem;
    img {
      padding: 8px;
      cursor: pointer;
    }
    .disabled {
      opacity: 0.3;
      cursor: default;
    }
    & img:nth-child(1) {
      transform: rotate(270deg);
    }
    & img:nth-child(3) {
      transform: rotate(90deg);
    }
  }
`;

const ResultButton = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 1.2rem;
  color: black;
  background-color: ${(props) => (props.isClicked ? '#C3F474' : '#B3B3B3')};
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
`;

const Back = styled.div`
  width: 100vw;
  text-decoration: underline;
  font-family: Pretendard;
  font-size: 1.2rem;
  cursor: pointer;
`;
