import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes(process.env.REACT_APP_ADMIN_PATH))
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
