import photo1 from './project10-1.png';
import photo2 from './project10-2.png';
import photo3 from './project10-3.png';

const project10 = {
  id: 10,
  name: '시간표 아티스트',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '김민정, 최한비, 권태영, 오혜린',
    },
    {
      position: 'BackEnd Developer',
      names: '차소연, 김혜빈, 조민서, 노하은, 이소정',
    },
    {
      position: 'UXUI Designer',
      names: '장예원, 허지원',
    },
  ],
  about:
    '‘망한’ 시간표를 친구들과 공유하는 문화에서 착안한, 이화여자대학(원)생들을 위한 시간표 채점 및 공유 서비스입니다. 건물간 거리, 이동난이도, 공강 유무 등 일반적으로 좋은 시간표의 조건이라 여겨지는 기준을 선별하여 채점 알고리즘을 설계했습니다. 14개의 유형 중 한가지의 유형으로 채점된 재미있는 결과표를 제공합니다. 랭킹보드 페이지에서 점수별, 인기순으로 정렬된 친구들의 시간표를 보며 좋아요와 댓글로 소통하는 커뮤니티 기능도 제공합니다.',
  links: {
    website: 'https://www.time-table-artist.com/',
    github: 'https://github.com/SamwaMoney',
  },
  photos: [photo1, photo2, photo3],
};

export default project10;
