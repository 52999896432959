import React, { useState } from 'react';
import InputLine from '../../components/common/InputLine';
import Button from '../../components/common/Button';
import AdminMain from './AdminMain';
import { loginAdmin } from '../../lib/api/admin';

const AdminLogin = () => {
  const [input, setInput] = useState('');
  const [isLogin, setIsLogin] = useState(!!localStorage.getItem('token'));
  const _handleSubmit = (e) => {
    e.preventDefault();
    loginAdmin(input)
      .then((res) => {
        if (res === false) alert('비밀번호가 틀렸거나, 에러가 발생했습니다.');
        else setIsLogin(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      {isLogin ? (
        <AdminMain />
      ) : (
        <>
          <h1 style={{ margin: '5rem 0' }}>EFUB ADMIN</h1>
          <form onSubmit={_handleSubmit}>
            <InputLine
              value={input}
              onChange={(e) => setInput(e.target.value)}
              type="text"
              placeholder="PASSWORD"
              width="20rem"
            />
            <Button type="submit" width={20}>
              입력
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default AdminLogin;
