import photo1 from './project11-1.png';
import photo2 from './project11-2.png';
import photo3 from './project11-3.png';

const project11 = {
  id: 11,
  name: 'E-DAY (이데이)',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '김예린, 이서진, 김현수, 최예빈',
    },
    {
      position: 'BackEnd Developer',
      names: '권민아, 최윤지, 송채원, 이한나',
    },
    {
      position: 'UXUI Designer',
      names: '김수연, 김이원',
    },
  ],
  about:
    '예비 이화인을 위한 퀴즈 서비스. 예비 벗들이 개강 D-7부터 하루하루 열리는 퀴즈를 맞추며 이화여대에 대해 알아가는 퀴즈+정보 사이트입니다. 이화인으로서 알아두면 쓸모 있는, 알아두면 좋을 지식과 꿀팁들을 받아갈 수 있는 유용한 사이트입니다. D-7부터 퀴즈를 하나씩 맞출 때마다 메인 화면에 있는 학교 지도에 색이 입혀지면서, 개강 날에는 색이 모두 칠해진 학교 지도를 얻을 수 있습니다.',
  links: {
    website: '',
    github: 'https://github.com/EFUB-EDAY',
  },
  photos: [photo1, photo2, photo3],
};

export default project11;
