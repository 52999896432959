import photo1 from './project13-1.png';

const project13 = {
  id: 13,
  name: `Let's-JUPJUP`,
  team: [
    {
      position: 'FrontEnd Developer',
      names: '송지민 김민정',
    },
    {
      position: 'BackEnd Developer',
      names: '박가영 송채원',
    },
    {
      position: 'UXUI Designer',
      names: '김수연',
    },
  ],
  about:
    '성동구를 위한 주민 주도형 플로깅 주최/참여 웹서비스입니다. 연령,성별,반려동물 동반 여부를 선택하여 모집글을 게시하거나 플로깅에 참여할 수 있습니다. 성동구 공식행사를 모아 볼 수 있는 화면과 플로깅을 완료 한 뒤 쓰레기 처리를 돕기 위한 성동구 쓰레기통 지도를 제공합니다. 이외에도 알림, 댓글, 찜하기, 뱃지 리뷰하기, 신고하기, 쓰레기통 상태 제보, 카카오톡 공유하기 등의 기능을 제공합니다. 2023 제2회 SW개발 공모전 : 피우다 프로젝트(성동구 공공서비스 문제 해결) 우수상 수상작입니다.',
  links: {
    website: 'https://lets-jupjup.com/',
    github: 'https://github.com/Lets-JUPJUP',
  },
  photos: [photo1],
};

export default project13;
