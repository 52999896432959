import project1 from './project1/project1';
import project2 from './project2/project2';
import project3 from './project3/project3';
import project4 from './project4/project4';
import project5 from './project5/project5';
import project6 from './project6/project6';
import project7 from './project7/project7';
import project8 from './project8/project8';
import project9 from './project9/project9';
import project10 from './project10/project10';
import project11 from './project11/project11';
import project12 from './project12/project12';
import project13 from './project13/project13';
import project14 from './project14/project14';
import project15 from './project15/project15';

// 플젝 info 추가 필요

const projects = {
  1: project1,
  2: project2,
  3: project3,
  4: project4,
  5: project5,
  6: project6,
  7: project7,
  8: project8,
  9: project9,
  10: project10,
  11: project11,
  12: project12,
  13: project13,
  14: project14,
  15: project15,
};

export default projects;
