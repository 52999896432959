import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ModalFrame from '../common/ModalFrame';
import ModalFrameMobile from '../common/ModalFrameMobile';
import Button from '../common/Button';
import { TitleText } from '../../lib/styles/styledAbout';

const CurriModal = ({ position, _handleModal }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [buttons, setButtons] = useState([true, false, false]);

  const _handleSelect = (index) => {
    if (index === 0) setButtons([true, false, false]);
    else if (index === 1) setButtons([false, true, false]);
    else setButtons([false, false, true]);
  };

  return (
    <>
      {isMobile ? (
        <ModalMobile inner top={position} _handleModal={_handleModal}>
          <TitleText eng style={{ marginTop: '40px' }}>
            EFUB Curriculum
          </TitleText>
          <ButtonWrapper>
            <Button
              round
              eng
              small
              blue
              width="6"
              filled={buttons[0]}
              onClick={() => _handleSelect(0)}
              style={{ fontSize: '12px', marginRight: '10px' }}
            >
              FrontEnd
            </Button>
            <Button
              round
              eng
              small
              blue
              width="6"
              filled={buttons[1]}
              onClick={() => _handleSelect(1)}
              style={{ fontSize: '12px', marginRight: '10px' }}
            >
              BackEnd
            </Button>
            <Button
              round
              eng
              small
              blue
              width="6"
              filled={buttons[2]}
              onClick={() => _handleSelect(2)}
              style={{ fontSize: '12px' }}
            >
              Designer
            </Button>
          </ButtonWrapper>
          <ScrollBox style={{ height: '60vh' }}>
            {buttons[0] ? (
              <>
                {front.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            ) : buttons[1] ? (
              <>
                {back.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            ) : (
              <>
                {design.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            )}
          </ScrollBox>
        </ModalMobile>
      ) : (
        <Modal
          inner
          top={position}
          style={{ padding: '3rem' }}
          _handleModal={_handleModal}
        >
          <TitleText eng style={{ fontSize: '30px' }}>
            EFUB Curriculum
          </TitleText>
          <ButtonWrapper>
            <Button
              round
              blue
              width="10"
              filled={buttons[0]}
              onClick={() => _handleSelect(0)}
              style={{ marginRight: '20px' }}
            >
              FrontEnd
            </Button>
            <Button
              round
              blue
              width="10"
              filled={buttons[1]}
              onClick={() => _handleSelect(1)}
              style={{ marginRight: '20px' }}
            >
              BackEnd
            </Button>
            <Button
              round
              blue
              width="10"
              filled={buttons[2]}
              onClick={() => _handleSelect(2)}
            >
              Designer
            </Button>
          </ButtonWrapper>
          <ScrollBox>
            <Line
              style={{ borderBottom: '1px solid gray', marginBottom: '10px' }}
            >
              <Week>주차</Week>
              <Subject style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                주제
              </Subject>
            </Line>
            {buttons[0] ? (
              <>
                {front.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            ) : buttons[1] ? (
              <>
                {back.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            ) : (
              <>
                {design.map((item) => {
                  return (
                    <Line>
                      <Week>{item.week}</Week>
                      <Subject>{item.subject}</Subject>
                    </Line>
                  );
                })}
              </>
            )}
          </ScrollBox>
        </Modal>
      )}
    </>
  );
};

export default CurriModal;

const Modal = styled(ModalFrame)`
  width: 85%;
`;

const ModalMobile = styled(ModalFrameMobile)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5rem 0rem 2rem 0rem;
  @media (max-width: 767px) {
    margin: 3rem 0rem 2rem 0rem;
  }
`;

const ScrollBox = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    width: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 100px;
    border: 0.1px solid white;
  }
`;

const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 60px;
  @media (max-width: 767px) {
    min-height: 50px;
    border-bottom: 1px solid gray;
  }
`;

const Week = styled.div`
  min-width: 150px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: bold;
  @media (max-width: 767px) {
    min-width: 80px;
    font-size: 12px;
  }
`;

const Subject = styled.div`
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto';
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const front = [
  { week: '1 - 1주차', subject: '브레인스토밍 데이' },
  { week: '1 - 2주차', subject: '개발팀 온보딩' },
  { week: '1 - 3주차', subject: 'HTML&CSS, Javascript 기초' },
  { week: '1 - 4주차', subject: 'JavaScript 심화' },
  { week: '1 - 5주차', subject: 'React 입문' },
  { week: '1 - 6주차', subject: 'React Hooks' },
  {
    week: '1 - 7주차',
    subject: 'React-Router-Dom, React Styles, 배포',
  },
  {
    week: '1 - 7주차',
    subject: '[리드] BackEnd-FrontEnd 합동 토이 프로젝트',
  },
  {
    week: '1 - 8주차',
    subject: 'React 상태 관리 라이브러리',
  },
  {
    week: '1 - 8주차',
    subject: '[인턴] BackEnd-FrontEnd 합동 토이 프로젝트',
  },
  { week: '1 - 9주차', subject: 'HTTP Request' },
  { week: '1 - 10주차', subject: 'React CRUD 서버 통신 실습' },

  { week: '2 - 1주차', subject: '퍼비톡' },
  { week: '2 - 2주차', subject: 'TypeScript 기초 문법(1)' },
  { week: '2 - 3주차', subject: 'TypeScript 기초 문법(2)' },
  { week: '2 - 4주차', subject: 'TypeScript + React (1)' },
  { week: '2 - 5주차', subject: 'Recoil + React-query' },
  { week: '2 - 6주차', subject: 'TypeScript + React (2)' },
  { week: '2 - 7주차', subject: 'Next.js 맛보기' },
  { week: '2 - 8주차', subject: 'Next.js 게시판 만들기' },
];

const back = [
  { week: '1 - 1주차', subject: '브레인스토밍 데이' },
  { week: '1 - 2주차', subject: '개발팀 온보딩' },
  { week: '1 - 3주차', subject: 'MySQL' },
  { week: '1 - 4주차', subject: 'API 개발과 테스트코드' },
  { week: '1 - 5주차', subject: '스프링 부트, 웹 MVC' },
  { week: '1 - 6주차', subject: '컨테이너, 빈, 의존성' },
  {
    week: '1 - 7주차',
    subject: 'AWS EC2&RDS 배포',
  },
  {
    week: '1 - 7주차',
    subject: '[리드] BackEnd-FrontEnd 합동 토이 프로젝트',
  },
  {
    week: '1 - 8주차',
    subject: 'JPA 소개 및 엔티티 매핑',
  },
  {
    week: '1 - 8주차',
    subject: '[인턴] BackEnd-FrontEnd 합동 토이 프로젝트',
  },
  { week: '1 - 9주차', subject: 'JPA 영속성 관리와 연관관계 관리' },
  { week: '1 - 10주차', subject: 'CI/CD & AWS 배포' },

  { week: '2 - 1주차', subject: 'Docker / QueryDSL' },
  { week: '2 - 2주차', subject: '퍼비톡 (1)' },
  { week: '2 - 3주차', subject: '퍼비톡 (2)' },
  { week: '2 - 4주차', subject: '단위테스트 / TDD' },
  { week: '2 - 5주차', subject: '통합테스트' },
  { week: '2 - 6주차', subject: 'Spring Security + JWT' },
  { week: '2 - 7주차', subject: 'Oauth2' },
  { week: '2 - 8주차', subject: 'DDD, 멀티 모듈' },

  {
    week: '*',
    subject:
      '2학년의 경우, BackEnd 인턴으로 지원 시 동아리 내에서 주최하는 DataBase 스터디 필참',
  },
];

const design = [
  { week: '1 - 1주차', subject: '브레인스토밍 데이' },
  {
    week: '1 - 2주차',
    subject: 'UX/UI 디자인',
  },
  {
    week: '1 - 3주차',
    subject: 'WEB의 UI',
  },
  {
    week: '1 - 4주차',
    subject: 'UX 리서치',
  },
  { week: '1 - 5주차', subject: '가설 검증' },
  { week: '1 - 6주차', subject: '개발자와의 협업 준비' },
  { week: '1 - 7주차', subject: '합동 세미나 (토이 프로젝트)' },
  { week: '1 - 8주차', subject: '브랜딩' },
  { week: '1 - 9주차', subject: 'SWS : 여름 프로젝트 디자인 1' }, // 수정중
  {
    week: '1 - 10주차',
    subject: 'SWS : 여름 프로젝트 디자인 2',
  },
];
