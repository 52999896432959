import client from './client';

const setHeader = () => {
  client.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  });
};

export const loginAdmin = async (password) => {
  try {
    const res = await client.post('login', { password: password });
    if (res.status === 200) {
      localStorage.setItem('token', res.data);
      window.location.reload();
      setHeader();
    }
  } catch (e) {
    if (String(e).includes('Error')) return false;
  }
};

export const getApplicationDetail = async (userId) => {
  setHeader();
  try {
    const res = await client.get(`admin/application/${userId}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getApplicationListAll = async (field) => {
  setHeader();
  try {
    const res = await client.get(`admin/applicant/${field}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getApplicationListPass = async (field) => {
  setHeader();
  try {
    const res = await client.get(`admin/applicant/mid/${field}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const checkResult = async (userId, result) => {
  setHeader();
  try {
    const res = await client.patch(`admin/application/${userId}/mid/${result}`);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const finalPass = async (userId) => {
  setHeader();
  try {
    const res = await client.patch(`admin/application/${userId}/final/pass`);
    return res;
  } catch (e) {
    console.log(e);
  }
};
