import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Wrapper, Title, Subtitle } from './RecruitmentThankyouPage';
import { useRecoilValue } from 'recoil';
import { serverState } from '../../lib/store/adminAtom';

import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import PcBanner from '../../components/recruitment/PcBanner';
import PcDetail from '../../components/recruitment/PcDetail';
import MobileBanner from '../../components/recruitment/MobileBanner';
import MobileDetail from '../../components/recruitment/MobileDetail';
import Left from '../../assets/recruitment/Left.png';
import Right from '../../assets/recruitment/Right.png';

const BackgroundImg = styled.img`
  width: 20rem;
  top: 60rem;
  left: ${(props) => props.left || 'none'};
  right: ${(props) => props.right || 'none'};
  z-index: 1;
  position: absolute;
  @media (max-width: 1023px) {
    width: 0px;
  }
`;

const RecruitmentMainPage = ({ onSelect }) => {
  onSelect('RECRUITMENT');
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const isServerOpen = useRecoilValue(serverState);
  return (
    <>
      {!isServerOpen ? (
        <Wrapper>
          <Title>지금은 모집 기간이 아닙니다.</Title>
          <Subtitle>5기 모집은 2025년 2월에 예정되어 있습니다.</Subtitle>
          <Link to="/">
            <div style={{ height: '50px' }} />
            <Button width="14" onClick={() => onSelect('ABOUT')}>
              처음으로 돌아가기
            </Button>
          </Link>
        </Wrapper>
      ) : isMobile ? (
        <>
          <MobileBanner />
          <MobileDetail />
        </>
      ) : (
        <>
          <PcBanner />
          <PcDetail />
          <BackgroundImg src={Left} alt="leftImg" left="0" />
          <BackgroundImg src={Right} alt="rightImg" right="0" />
        </>
      )}
    </>
  );
};

export default RecruitmentMainPage;
