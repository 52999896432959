import photo1 from './project12-1.png';
import photo2 from './project12-2.png';
import photo3 from './project12-3.png';
import photo4 from './project12-4.png';
import photo5 from './project12-5.png';
import photo6 from './project12-6.png';
import photo7 from './project12-7.png';
import photo8 from './project12-8.png';
import photo9 from './project12-9.png';

const project12 = {
  id: 12,
  name: '대행사',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '이주희, 이서진',
    },
    {
      position: 'BackEnd Developer',
      names: '권민아, 김예지',
    },
    {
      position: 'UXUI Designer',
      names: '이주희, 이서진',
    },
  ],
  about:
    '대행사는 동아리 공연, 과 행사 같은 대학생 주최의 작은 행사를 모아볼 수 있는 서비스입니다. 대학 행사에 참여하고 싶은 사용자들이 다양한 곳에 흩어져 있는 행사 정보를 한 번에 모아보고 저장할 수 있으며, 행사 참가 신청을 할 수 있습니다. 행사 주최 측은 행사를 더 많은 사용자들에게 홍보할 수 있으며, 행사 신청 과정과 신청자 정보를 모아보는 일을 대행사가 대신해 줌으로써 더 쉽게 행사를 홍보할 수 있습니다.',
  links: {
    website: 'https://dae-haengsa-front.vercel.app',
    github: 'https://github.com/TEAM-DHS',
  },
  photos: [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
  ],
};

export default project12;
