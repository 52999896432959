import photo1 from './project15-1.png';
import photo2 from './project15-2.png';
import photo3 from './project15-3.png';
import photo4 from './project15-4.png';

const project15 = {
  id: 15,
  name: '페이퍼즈(PAPERS)',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '오혜린 조정민',
    },
    {
      position: 'BackEnd Developer',
      names: '노하은 모수지',
    },
    {
      position: 'UXUI Designer',
      names: '오혜린 조정민',
    },
  ],
  about:
    '페이퍼즈는 블로그, 기사 등의 링크를 첨부하고, 자신의 의견을 더한 스크랩을 작성하고 공유하는 플랫폼입니다. 구글 로그인으로 가입이 가능하고, 링크를 첨부하면 미리보기가 자동으로 생성됩니다. 8개의 카테고리와 태그로 스크랩을 분류할 수 있고 이를 통한 검색도 가능합니다. 스크랩에 댓글 및 좋아요를 할 수 있습니다. 또한 프로필 이미지 및 한 줄 소개로 자신을 표현하고 다른 유저를 팔로우할 수 있습니다. 작성한 스크랩을 폴더를 만들어 분류하고 공유할 수 있습니다. 다크 모드도 지원합니다.',
  links: {
    website: '',
    github: 'https://github.com/EFUB-Papers',
  },
  photos: [photo1, photo2, photo3, photo4],
};

export default project15;
