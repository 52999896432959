import photo1 from './project6-1.png';
import photo2 from './project6-2.png';

const project6 = {
  id: 6,
  name: '梨상청',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '장아연 김소현 최은비',
    },
    {
      position: 'BackEnd Developer',
      names: '이소림 박소현 김은서 이선의 김명지',
    },
    {
      position: 'UXUI Designer',
      names: '김채령 조정연',
    },
  ],
  about:
    '기존 에브리타임 날씨게시판의 시각정보 부족과, 기상정보를 종합적으로 파악할 수 없다는 문제점에서 시작하여 고안한 이화인을 위한 기상정보 서비스입니다. 오픈 API를 활용해 매일의 기상정보를 제공하며, 옷에 대한 투표를 통해 에브리타임 날씨게시판의 기능을 시각적으로 확장합니다. 이화그린 색의 야잠, 뽀글이후드 등을 입은 이화곰돌이를 통해 기온과 강수상태가 어떤지 파악할 수 있습니다.',
  links: {
    website: '',
    github: 'https://github.com/EFUB-TEAM4',
  },
  photos: [photo1, photo2],
};

export default project6;
