import photo1 from './project3-1.png';
import photo2 from './project3-2.png';
import photo3 from './project3-3.png';
import photo4 from './project3-4.png';

const project3 = {
  id: 3,
  name: 'Switme',
  team: [
    {
      position: 'Frontend Developer',
      names: '최윤재 이로움 박선영',
    },
    {
      position: 'Backend Developer',
      names: '문효진 김정은 윤효정 신인류',
    },
    {
      position: 'UX/UI Designer',
      names: '이은빈',
    },
  ],
  about:
    '공부하는 모습을 녹화해 업로드하거나 실시간 스트리밍하는 스터디 윗 미의 뜻을 차용한 서비스로 스터디 모집, 스톱워치, 소통을 위한 채팅을 중점 기능으로 두고 있습니다.',
  links: {
    website: '',
    github: 'https://github.com/SwithMe',
  },
  photos: [photo1, photo2, photo3, photo4],
};

export default project3;
