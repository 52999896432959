import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '../../components/common/Button';

const AdminMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <h1 style={{ margin: '5rem 0' }}>EFUB ADMIN</h1>
      <h3 style={{ marginBottom: '3rem' }}>👇🏻 지원서 조회하기 👇🏻</h3>
      <Button
        width={20}
        onClick={() => navigate(location.pathname + '/list/all')}
        style={{ marginBottom: '2rem' }}
      >
        전체 지원자 조회
      </Button>
      <Button
        width={20}
        onClick={() => navigate(location.pathname + '/list/pass')}
      >
        1차 합격자 조회
      </Button>
    </>
  );
};

export default AdminMain;
