import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import InputBox from '../components/common/InputBox';
import Button from '../components/common/Button';
import Banner from '../components/contact/Banner';
import Loading from '../components/common/Loading';

import { sendEmail } from '../lib/api/contact';
import { useRecoilValue } from 'recoil';
import { serverState } from '../lib/store/adminAtom';

const FormBox = styled.div`
  margin-bottom: 5rem;
  width: 70%;
  @media (max-width: 767px) {
    width: 90%;
    margin-bottom: 0rem;
  }
`;

const Label = styled.div`
  font-size: 20px;
  font-family: Roboto;
  margin-bottom: 1rem;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

const Grid = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Text = styled.div`
  font-size: 15px;
  font-family: Roboto;
  font-weight: 700;
`;

const Link = styled.a`
  font-size: 15px;
  font-family: Roboto;
  text-decoration: underline;
`;

const ContactPage = () => {
  const isServerOpen = useRecoilValue(serverState);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    writerEmail: '',
    content: '',
  });

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const _handleClick = async () => {
    setLoading(true);
    await sendEmail(inputs);
    setLoading(false);
  };

  return (
    <>
      <Banner isOpen={isServerOpen} />
      {isServerOpen ? (
        <>
          <FormBox>
            <Label>Email</Label>
            <InputBox
              name="writerEmail"
              value={inputs.writerEmail}
              onChange={onChange}
              placeholder="답변 받을 이메일 주소 입력"
              height={isMobile ? '3rem' : '5.5rem'}
            />
            <Label>문의 내용</Label>
            <InputBox
              name="content"
              value={inputs.content}
              onChange={onChange}
              placeholder="EFUB과 협업하고 싶어요! / 지원 기준이 어떻게 되나요?"
            />
          </FormBox>
          {isMobile ? (
            <Button style={{ width: '90%' }} onClick={() => _handleClick()}>
              전송하기
            </Button>
          ) : (
            <Button
              width="10"
              style={{ marginBottom: '10rem' }}
              onClick={() => _handleClick()}
            >
              전송하기
            </Button>
          )}
          {loading ? <Loading /> : <></>}
        </>
      ) : (
        <FormBox>
          <Label style={{ textAlign: 'center' }}>
            아래 연락처을 통해 문의 사항을 남겨주세요.
          </Label>
          <Grid>
            <Text>이메일</Text>
            <Link href="mailto:ewhaefub@gmail.com" target="_blank">
              ewhaefub@gmail.com
            </Link>
            <Text>카카오톡 채널</Text>
            <Link href="http://pf.kakao.com/_xeKbwG" target="_blank">
              @efub_4th
            </Link>
          </Grid>
        </FormBox>
      )}
    </>
  );
};

export default ContactPage;
