import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/common/Button';
import InputLine from '../../components/common/InputLine';
import { checkPass } from '../../lib/api/recruitment';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 60%;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const Title = styled.div`
  font-size: 3rem;
  margin: 3rem;
  font-family: Roboto;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 20px;
    width: 15rem;
    margin: 2rem;
    display: flex;
    justify-content: center;
  }
`;
const ListBox = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  width: 90%;
  margin: 2rem 0rem 3rem 0rem;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  text-align: center;
  @media (max-width: 767px) {
    /* align-items: flex-start; */
    padding: 1.5rem;
    margin: 1rem 0rem 2rem 0rem;
  }
`;

// 1차 합격자용 Box
const PassListBox = styled(ListBox)`
  flex-direction: row;
  text-align: left;
  justify-content: space-evenly;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 1023px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const SubText = styled.div`
  font-family: Roboto;
  font-weight: bold;
  line-height: 2rem;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 1.5rem;
  }
`;

const HyperText = styled(SubText)`
  text-decoration: underline;
  cursor: pointer;
`;

const DescBox = styled.div`
  width: 70%;
`;

const LastBox = styled(DescBox)`
  text-align: center;
  margin: 2rem 0rem 4rem 0;

  @media (max-width: 767px) {
    margin: 2rem 0;
  }
`;

const BtnBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const RecruitmentPassPage = () => {
  const [inputs, setInputs] = useState({
    name: '',
    phoneNo: '',
    password: '',
  });

  const onChange = (e) => {
    let { value, name } = e.target;
    if (!(name === 'name')) value = value.replace(/[^0-9]/g, '');
    if (name === 'password') value = value.slice(0, 4);
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [pass, setPass] = useState(false);
  const [fail, setFail] = useState(false);
  const [interviewDate, setInterviewDate] = useState(null);

  const dateString = '02/29/2024 16:00';

  // 1차 & 2차 합격 발표 선택 가능 'mid', 'final'
  const _handleClick = async () => {
    const res = await checkPass({
      body: inputs,
      step: 'final',
    });
    if (res && res.data.result === '합격') {
      setPass(true);
      setInterviewDate(res.data.interviewDate);
    } else if (res && res.data.result === '불합격') setFail(true);
  };

  // 현재 합격자 발표 단계 선택 가능 - '1차', '최종'
  const stepStatus = '최종';

  return (
    <>
      {!pass && !fail && (
        <>
          <Title>합격자 조회</Title>
          <Container>
            <InputLine
              name="name"
              value={inputs.name}
              label="이름"
              placeholder="지원자 이름"
              onChange={onChange}
            />
            <InputLine
              name="phoneNo"
              value={inputs.phoneNo}
              label="전화번호"
              placeholder="'-' 없이 입력"
              onChange={onChange}
            />
            <InputLine
              name="password"
              value={inputs.password}
              type="password"
              label="비밀번호"
              placeholder="4자리 입력"
              onChange={onChange}
            />
            <Button
              widthXS
              width="8"
              onClick={() => _handleClick()}
              style={{ marginBottom: '10rem' }}
            >
              조회하기
            </Button>
          </Container>
        </>
      )}
      {pass && (
        <>
          <Title>{stepStatus} 합격자 발표</Title>
          {stepStatus === '1차' && (
            <>
              <PassListBox>
                <div>
                  <Text>안녕하세요, EFUB 4기 운영진입니다. </Text>
                  <Text>지원자분께서는 면접 대상자로 선정되셨습니다! 🎉 </Text>
                  <Text>면접 일자를 확인해주세요.</Text>
                </div>
                <div>
                  <SubText>{'2월 ' + interviewDate}</SubText>
                  <HyperText
                    onClick={() =>
                      window.open(
                        'https://ewha.zoom.us/j/85326752314?pwd=bNdhDMCAtxJORygobS17XlNB1Ieg8Y.1',
                        '_blank',
                      )
                    }
                  >
                    면접 ZOOM 입장 링크 &gt;
                  </HyperText>
                  <SubText>회의 ID : 853 2675 2314</SubText>
                  <SubText>암호 : 820580</SubText>
                </div>
              </PassListBox>
              <DescBox>
                <SubText style={{ fontWeight: 'normal', textAlign: 'center' }}>
                  면접 참석 여부를 확인하기 위해 본인의 면접 시간 5분 전까지
                  입장을 부탁드립니다.
                  <br />
                  입장 후 대기실에 계시면 임원진이 정시에 입장 수락을 할
                  예정입니다.
                  <br />
                  <br />
                  <b>아래 구글 폼 동의서를 제출하셔야 면접이 확정됩니다.</b>
                </SubText>
              </DescBox>
              <LastBox>
                <HyperText
                  onClick={() =>
                    window.open('https://forms.gle/ALPHLRpm6PhXoXRt9', '_blank')
                  }
                >
                  https://forms.gle/ALPHLRpm6PhXoXRt9
                </HyperText>
              </LastBox>
            </>
          )}
          {stepStatus === '최종' && (
            <>
              <ListBox>
                <Text>안녕하세요. EFUB 4기 운영진입니다.</Text>
                <Text>EFUB 4기의 새로운 퍼비가 되신 걸 축하드립니다! 🎉</Text>
              </ListBox>
              <DescBox>
                <SubText style={{ fontWeight: 'normal', textAlign: 'center' }}>
                  엄청난 경쟁률을 뚫고 합격하신 여러분들과 앞으로 1년 동안 함께
                  웹 개발의 길을 나아가게 되어 영광으로 생각합니다. 먼저
                  여러분들의 합격 확인을 위해 아래 구글 폼을 작성해주시면
                  감사하겠습니다.
                  <br />
                  공지톡과 사담톡은 구글 폼 수합이 완료되면 초대해드릴
                  예정이므로, 빠른 제출 부탁드립니다. 다시 한번 지원에
                  감사드리며, 3월 5일 OT에서 뵙도록 하겠습니다 :)
                </SubText>
              </DescBox>
              <LastBox>
                <SubText>EFUB 4기 운영진 일동</SubText>
                <br />
                <HyperText
                  onClick={() =>
                    window.open('https://forms.gle/UTQCtzcXMBGwixy77', '_blank')
                  }
                >
                  https://forms.gle/UTQCtzcXMBGwixy77
                </HyperText>
              </LastBox>
            </>
          )}
          <BtnBox>
            <Button widthXS width="8" onClick={() => setPass(false)}>
              돌아가기
            </Button>
          </BtnBox>
        </>
      )}
      {fail && (
        <>
          <Title>{stepStatus} 합격자 발표</Title>
          <ListBox>
            <Text>안녕하세요. EFUB 4기 운영진입니다.</Text>
            <Text>아쉽게도 지원자 분을 EFUB에 모시지 못하게 됐습니다.</Text>
          </ListBox>
          <DescBox>
            <SubText style={{ fontWeight: 'normal', textAlign: 'center' }}>
              지원자 분의 우수한 역량과 빛나는 열정을 볼 수 있었음에도 불구하고
              운영의 한계로 더 많은 퍼비분들을 선발할 수 없어 안타깝고 죄송한
              마음입니다. 모든 지원자 분들의 앞날을 저희 4기 운영진이
              응원하겠습니다.{' '}
              {stepStatus === '최종' &&
                '면접 점수에 대한 개별적인 문의는 답변드리지 못하는 점 양해 부탁드립니다. '}
              EFUB에 관심을 가지고 소중한 시간 내어 지원해주셔서 다시 한 번
              감사드립니다.
            </SubText>
          </DescBox>
          <LastBox>
            <SubText>EFUB 4기 운영진 일동</SubText>
          </LastBox>
          <BtnBox>
            <Button widthXS width="8" onClick={() => setFail(false)}>
              돌아가기
            </Button>
          </BtnBox>
        </>
      )}
    </>
  );
};

export default RecruitmentPassPage;
