import { useState, useCallback } from 'react';
import { Routes, Route } from 'react-router';
import styled from 'styled-components';
import Header from '../../components/common/Header';
import Responsive from '../../components/common/Responsive';
import AdminLogin from './AdminLogin';
import AdminListAll from './AdminListAll';
import AdminDetail from './AdminDetail';
import AdminListPass from './AdminListPass';

const Main = styled(Responsive)`
  min-height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AdminRoutes = () => {
  const [menu, setMenu] = useState('');
  const onSelect = useCallback((menu) => setMenu(menu), []);

  return (
    <Main>
      <Header menu={menu} onSelect={onSelect} />
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/list/all" element={<AdminListAll />} />
        <Route path="/list/all/:id" element={<AdminDetail />} />
        <Route path="/list/pass" element={<AdminListPass />} />
      </Routes>
    </Main>
  );
};

export default AdminRoutes;
