import photo1 from './project9-1.png';
import photo2 from './project9-2.png';
import photo3 from './project9-3.png';
import photo4 from './project9-4.png';
import photo5 from './project9-5.png';
import photo6 from './project9-6.png';

const project9 = {
  id: 9,
  name: 'BagEasy',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '곽지우 김예진 송지민 이주희 조정민',
    },
    {
      position: 'BackEnd Developer',
      names: '김예지 모수지 박가영 조현영',
    },
    {
      position: 'UXUI Designer',
      names: '김서연 조은비',
    },
  ],
  about:
    '교환학생을 위한 짐 거래 서비스를 제공하는 웹 애플리케이션입니다. 에브리타임, 블로그, 카페 등 다양한 플랫폼에 분산되어 있던 교환학생 짐 판매글을 통합할 수 있는 중고 거래 플랫폼을 제시합니다. 판매자는 파견교를 설정하여 판매글을 게시하고, 채팅으로 거래를 이어갈 수 있습니다. 더 편리한 서비스 이용을 위해 구글 소셜 로그인, 찜하기, 거래 성사 여부 표시 기능을 제공합니다.',
  links: {
    website: '',
    github: 'https://github.com/EFUB-SURFERS',
  },
  photos: [photo1, photo2, photo3, photo4, photo5, photo6],
};

export default project9;
