import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 73vh;
  @media (max-width: 767px) {
    width: 85%;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.div`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1.7rem;
`;

const SubText = styled.div`
  font-family: Roboto;
  font-weight: normal;
  line-height: 1.7rem;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 1.5rem;
  }
  margin: 4rem 0;
`;

const RecruitmentThankyouPage = ({ onSelect }) => {
  onSelect('RECRUITMENT');
  return (
    <Wrapper>
      <Title>지원해주셔서 감사합니다.</Title>
      <Subtitle>EFUB에서 함께할 당신의 앞날을 응원합니다!</Subtitle>
      {window.location.href.includes('designer') && (
        <SubText>
          * 4기 디자이너 추가 모집 기간(2.23~2.29) 동안 제출해주신 지원자 분들의
          1차 서류 및 2차 면접 결과는 웹사이트가 아닌 <b>문자 메시지</b>로
          발표될 예정입니다.
          <br />
          웹사이트 조회 결과와 혼동 없으시길 바랍니다!
        </SubText>
      )}
      <Link to="/">
        <Button width="14" onClick={() => onSelect('ABOUT')}>
          처음으로 돌아가기
        </Button>
      </Link>
    </Wrapper>
  );
};

export default RecruitmentThankyouPage;
