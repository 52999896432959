import React from 'react';
import styled from 'styled-components';
import checkboxTrue from '../../assets/recruitment/checkbox/checkbox-true.svg';
import checkboxFalse from '../../assets/recruitment/checkbox/checkbox-false.svg';

const Wrapper = styled.div`
  width: 16rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  font-size: 1.25rem;
  margin-left: 2.5rem;
  font-family: Roboto;
  font-weight: bold;
`;

const Image = styled.img`
  width: 2rem;
  height: ${(props) => props.height || '2'}rem;
`;

const Checkbox = ({ label, checked, onToggle, height }) => (
  <Wrapper>
    {checked ? (
      <Image
        onClick={onToggle}
        src={checkboxTrue}
        alt="checkboxTrue"
        height={height}
      />
    ) : (
      <Image
        onClick={onToggle}
        src={checkboxFalse}
        alt="checkboxFalse"
        height={height}
      />
    )}
    {label && <Label>{label}</Label>}
  </Wrapper>
);

export default Checkbox;
