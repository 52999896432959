import photo1 from './project8-1.png';
import photo2 from './project8-2.png';
import photo3 from './project8-3.png';

const project8 = {
  id: 5,
  name: 'STEADY DAY',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '박지현 이소진 권혜인 도하연',
    },
    {
      position: 'BackEnd Developer',
      names: '김민주 김윤지 변지은 최빈',
    },
    {
      position: 'UXUI Designer',
      names: '박하늘 송유경 ',
    },
  ],
  about:
    'STEADY는 스터디 관리를 해주는 웹 서비스입니다. 회원가입을 한 누구나 스터디를 개설할 수 있으며 기한 관리, 스터디 인증글 작성, 벌금 정산, 랭킹 제도, 투두리스트, 신고 등의 기능을 통해 STEADY는 유저들이 원활한 스터디 활동을 하도록 돕습니다. 스터디 부원들에게는 동기부여를, 스터디 관리가 부담스러워 스터디를 선뜻 개설하지 못하는 스터디장에게는 부담을 대신해주는 서비스를 제공하고 있습니다.',
  links: {
    website: '',
    github: 'https://github.com/EFUB-steady',
  },
  photos: [photo1, photo2, photo3],
};

export default project8;
