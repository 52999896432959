import photo1 from './project5-1.png';
import photo2 from './project5-2.png';
import photo3 from './project5-3.png';
import photo4 from './project5-4.png';

const project5 = {
  id: 5,
  name: 'Caker',
  team: [
    {
      position: 'FrontEnd Developer',
      names: '김하은 정다윤 김지원 도소현 최지민',
    },
    {
      position: 'BackEnd Developer',
      names: '김시연 김지인 김현진',
    },
    {
      position: 'UXUI Designer',
      names: '신희선 최지호',
    },
  ],
  about:
    "'CAKER'는 레터링 케이크 주문/판매 플랫폼으로, 레터링 케이크를 주문하고자 하는 구매자와 그에 맞는 서비스를 제공하는 판매자를 서로 연결해주어 기존의 불편함을 해소하고 원하는 케이크 가게를 손쉽게 찾게 해줍니다. (모바일 권장)",
  links: {
    website: 'https://bakery-front-rho.vercel.app/',
    github: 'https://github.com/Bakery-EFUB',
  },
  photos: [photo1, photo2, photo3, photo4],
};

export default project5;
