import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/common/Button';
import ListItem from '../../components/admin/ListItem';
import { ListItemContainer } from '../../components/admin/ListItem';

import { getApplicationListPass } from '../../lib/api/admin';
import { adminFieldState, adminIdState } from '../../lib/store/adminAtom';
import { useRecoilState } from 'recoil';

const AdminListPass = () => {
  const [currentField, setCurrentField] = useRecoilState(adminFieldState);
  const [currentId, setCurrentId] = useRecoilState(adminIdState);

  const [buttons, setButtons] = useState([
    {
      id: 0,
      name: '프론트리드',
      query: 'frontLead',
      state: currentField === 'frontLead',
    },
    {
      id: 1,
      name: '프론트인턴',
      query: 'frontIntern',
      state: currentField === 'frontIntern',
    },
    {
      id: 2,
      name: '백리드',
      query: 'backLead',
      state: currentField === 'backLead',
    },
    {
      id: 3,
      name: '백인턴',
      query: 'backIntern',
      state: currentField === 'backIntern',
    },
    {
      id: 4,
      name: '디자이너',
      query: 'designer',
      state: currentField === 'designer',
    },
  ]);
  const _handleSelect = (index) => {
    const initArray = buttons.map((item) => ({ ...item, state: false }));
    initArray[index].state = true;
    setButtons(initArray);
  };
  useEffect(() => {
    setCurrentField(buttons.filter((item) => item.state === true)[0].query);
  }, [buttons]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getApplicationListPass(currentField).then((res) => {
      setData(res);
      setCurrentId(res.map((item) => item.id));
    });
  }, [currentField]);

  return (
    <Wrapper>
      <h1>1차 합격자 조회</h1>
      <Link
        to={`/${process.env.REACT_APP_ADMIN_PATH}/list/all`}
        style={{ textDecoration: 'underline' }}
      >
        전체 합격자 리스트
      </Link>
      <ButtonWrapper>
        {buttons.map((item) => (
          <Button
            round
            blue
            width="8"
            filled={buttons[item.id].state}
            onClick={() => _handleSelect(item.id)}
            key={item.id}
          >
            {item.name}
          </Button>
        ))}
      </ButtonWrapper>
      <ListBox>
        <ListTitle isPass={true}>
          <div>이름</div>
          <div>학번</div>
          <div>파트</div>
          <div>단대 및 학과</div>
          <div>최종 합격</div>
        </ListTitle>
        {data ? (
          data.map((item) => (
            <ListItem
              key={item.id}
              isPass={true}
              item={item}
              setData={setData}
            />
          ))
        ) : (
          <h1>error</h1>
        )}
      </ListBox>
    </Wrapper>
  );
};

export default AdminListPass;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  font-family: Pretendard;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 50px 0 30px 0;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  border-radius: 10px;
  padding-bottom: 10px;
`;

const ListTitle = styled(ListItemContainer)`
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  & div {
    height: 3.5rem;
    font-weight: 700 !important;
  }
`;
