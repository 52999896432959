import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CheckBox from '../../components/common/CheckBox';

import { getApplicationListPass, finalPass } from '../../lib/api/admin';
import { adminFieldState } from '../../lib/store/adminAtom';
import { useRecoilValue } from 'recoil';

const ListItem = ({ item, isPass, setData }) => {
  const { id, name, pass, fixed, studentId, department, applicationField } =
    item;
  const navigate = useNavigate();
  const location = useLocation();

  const currentField = useRecoilValue(adminFieldState);
  const [finalResult, setFinalResult] = useState(item.finalResult);
  const _handleCheck = () => {
    finalPass(id).then((res) =>
      getApplicationListPass(currentField).then((res) => setData(res)),
    );
    setFinalResult(!finalResult);
  };

  return (
    <ListItemContainer
      onClick={() =>
        isPass ? undefined : navigate(location.pathname + '/' + id)
      }
      color={
        fixed === false
          ? 'transparent'
          : pass === false
          ? '#640000'
          : pass === true
          ? '#0C3A00'
          : 'transparent'
      }
      isPass={isPass}
    >
      {!isPass && <div>{id}</div>}
      <div>{name}</div>
      <div>{studentId}</div>
      <div>
        {applicationField === '리드개발자 - 프론트엔드'
          ? '프론트리드'
          : applicationField === '리드개발자 - 백엔드'
          ? '백리드'
          : applicationField === '인턴개발자 - 프론트엔드'
          ? '프론트인턴'
          : applicationField === '인턴개발자 - 백엔드'
          ? '백인턴'
          : applicationField}
      </div>
      <div>{department}</div>
      {isPass && (
        <div>
          <CheckBox checked={pass} onToggle={_handleCheck} height={1.5} />
        </div>
      )}
    </ListItemContainer>
  );
};

export default ListItem;

export const ListItemContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${(props) => (props.isPass ? 'transparent' : props.color)};
  cursor: ${(props) => (props.isPass ? 'default' : 'pointer')};

  & div {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding: 0 10px;
    text-align: center;
  }

  & div:nth-child(1) {
    width: ${(props) => (props.isPass ? 12 : 10)}%;
    font-weight: ${(props) => (props.isPass ? 600 : 400)};
  }
  & div:nth-child(2) {
    width: ${(props) => (props.isPass ? 15 : 12)}%;
    font-weight: ${(props) => (props.isPass ? 400 : 600)};
  }
  & div:nth-child(3) {
    width: ${(props) => (props.isPass ? 15 : 20)}%;
  }
  & div:nth-child(4) {
    width: ${(props) => (props.isPass ? 45 : 13)}%;
  }
  & div:nth-child(5) {
    width: ${(props) => (props.isPass ? 13 : 45)}%;
  }
`;
