import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const adminFieldState = atom({
  key: 'adminFieldState',
  default: 'frontLead',
});

export const adminIdState = atom({
  key: 'adminIdState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const serverState = atom({
  key: 'serverState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
