import project1 from '../../assets/project/project1.png';
import project2 from '../../assets/project/project2.png';
import project3 from '../../assets/project/project3.png';
import project4 from '../../assets/project/project4.png';
import project5 from '../../assets/project/project5.png';
import project6 from '../../assets/project/project6.png';
import project7 from '../../assets/project/project7.png';
import project8 from '../../assets/project/project8.png';
import project9 from '../../assets/project/project9.png';
import project10 from '../../assets/project/project10.png';
import project11 from '../../assets/project/project11.png';
import project12 from '../../assets/project/project12.png';
import project13 from '../../assets/project/project13.png';
import project14 from '../../assets/project/project14.jpg';
import project15 from '../../assets/project/project15.png';

// 프로젝트 사진 필요

const cards = {
  all: [
    { id: 9, name: 'BagEasy', file: project9 },
    { id: 10, name: '시간표 아티스트', file: project10 },
    { id: 11, name: 'E-DAY (이데이)', file: project11 },
    { id: 12, name: '대행사', file: project12 },
    { id: 13, name: `Let's-JUPJUP`, file: project13 },
    { id: 14, name: 'SweetMe', file: project14 },
    { id: 15, name: '페이퍼즈(PAPERS)', file: project15 },
    { id: 5, name: 'Caker', file: project5 },
    { id: 6, name: '梨상청', file: project6 },
    { id: 7, name: '라꾸라꾸', file: project7 },
    { id: 8, name: 'STEADY DAY', file: project8 },
    { id: 1, name: '맛집전공', file: project1 },
    { id: 2, name: 'EWHA CODIC', file: project2 },
    { id: 3, name: 'Switme', file: project3 },
    { id: 4, name: 'See Me', file: project4 },
  ],
  first: [
    { id: 1, name: '맛집전공', file: project1 },
    { id: 2, name: 'EWHA CODIC', file: project2 },
    { id: 3, name: 'Switme', file: project3 },
    { id: 4, name: 'See Me', file: project4 },
  ],
  second: [
    { id: 5, name: 'Caker', file: project5 },
    { id: 6, name: '梨상청', file: project6 },
    { id: 7, name: '라꾸라꾸', file: project7 },
    { id: 8, name: 'STEADY DAY', file: project8 },
  ],
  third: [
    { id: 9, name: 'BagEasy', file: project9 },
    { id: 10, name: '시간표 아티스트', file: project10 },
    { id: 11, name: 'E-DAY (이데이)', file: project11 },
    { id: 12, name: '대행사', file: project12 },
    { id: 13, name: `Let's-JUPJUP`, file: project13 },
    { id: 14, name: 'SweetMe', file: project14 },
    { id: 15, name: '페이퍼즈(PAPERS)', file: project15 },
  ],
};

export default cards;
